import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {CountryOpenApi, InvestorFiltersOpenApi} from "../../generated"
import {mapCountryToString} from "../address/country/country.mapper"

export type InvestorFilterType = "ID"
    | "AI_STATUS_DECLARED"
    | "CITY"
    | "COMPANY_NAME"
    | "COUNTRY"
    | "DEAL_GROUP_NAME"
    | "EMAIL"
    | "FULL_NAME"
    | "IMPORTED"
    | "INVESTOR_GROUP_NAME"
    | "STARTED"
    | "USER_ID"

export function assembleFilterOptions(): ActionsFilterType<InvestorFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "AI_STATUS_DECLARED",
            label: "AI Status Declared",
            value: "BOOLEAN"
        },
        {
            type: "CITY",
            label: "City",
            value: "STRING"
        },
        {
            type: "COMPANY_NAME",
            label: "Company Name",
            value: "STRING"
        },
        {
            type: "COUNTRY",
            label: "Country",
            value: {
                type: "ENUM",
                values: Object.entries(CountryOpenApi).map(c => ({
                    label: mapCountryToString(c[1]),
                    value: c[1]
                }))
            }
        },
        {
            type: "DEAL_GROUP_NAME",
            label: "Deal Group Name",
            value: "STRING"
        },
        {
            type: "EMAIL",
            label: "Email",
            value: "STRING"
        },
        {
            type: "FULL_NAME",
            label: "Full Name",
            value: "STRING"
        },
        {
            type: "IMPORTED",
            label: "Imported",
            value: "BOOLEAN"
        },
        {
            type: "INVESTOR_GROUP_NAME",
            label: "Investor Group Name",
            value: "STRING"
        },
        {
            type: "STARTED",
            label: "Started",
            value: "BOOLEAN"
        },
        {
            type: "USER_ID",
            label: "User ID",
            value: "STRING"
        }
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<InvestorFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<InvestorFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return {
        type: "FULL_NAME",
        value: undefined
    }
}

export function mapToInvestorFiltersOpenApi(filter: ActionsFilterTypeAndValueType<InvestorFilterType> | undefined): InvestorFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "AI_STATUS_DECLARED":
            return { aiStatusDeclared: filter.value }
        case "CITY":
            return { city: filter.value }
        case "COMPANY_NAME":
            return { companyName: filter.value }
        case "COUNTRY":
            return { country: filter.value }
        case "DEAL_GROUP_NAME":
            return { dealGroupName: filter.value }
        case "EMAIL":
            return { email: filter.value }
        case "FULL_NAME":
            return { fullName: filter.value }
        case "IMPORTED":
            return { imported: filter.value }
        case "INVESTOR_GROUP_NAME":
            return { investorGroupName: filter.value }
        case "STARTED":
            return { started: filter.value }
        case "USER_ID":
            return { userId: filter.value }
    }
}