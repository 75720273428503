import {
    AsynchronousActionStateOpenApi,
    AsynchronousActionTypeOpenApi,
    NotificationTypeOpenApi
} from "../../../../generated"
import {AsynchronousActionType} from "../action.type"
import {INVESTMENT, INVESTOR} from "../../../../paths"

export class NotificationAsynchronousActionType implements AsynchronousActionType {
    public id: string
    public created: Date
    public modified: Date
    public state: AsynchronousActionStateOpenApi
    public message?: string
    public processed?: Date
    public reference: NotificationReferenceType
    public series?: NotificationSeriesType
    public type: NotificationTypeOpenApi

    constructor({
        id,
        created,
        modified,
        state,
        message,
        processed,
        reference,
        series,
        type
    } : {
        id: string,
        created: Date,
        modified: Date,
        state: AsynchronousActionStateOpenApi,
        message?: string,
        processed?: Date,
        reference: NotificationReferenceType,
        series?: NotificationSeriesType,
        type: NotificationTypeOpenApi
    }) {
        this.id = id
        this.created = created
        this.modified = modified
        this.state = state
        this.message = message
        this.processed = processed
        this.reference = reference
        this.series = series
        this.type = type
    }

    get actionType(): AsynchronousActionTypeOpenApi {
        return AsynchronousActionTypeOpenApi.Notification
    }

    get details(): { title: string, content: string }[] {
        return [{
            title: "Notification Type",
            content: this.type
        }]
    }

    getLink(): { title: string, link: string } | undefined {
        if (this.reference.investmentId) {
            return {
                title: "Go to Investment",
                link: INVESTMENT(this.reference.investmentId)
            }
        }
        if (this.reference.investorId) {
            return {
                title: "Go to Investor",
                link: INVESTOR(this.reference.investorId)
            }
        }
    }

}

export type NotificationReferenceType = {
    investmentId?: string
    investorId?: string
    subscriptionLogId?: string
    syndicateInvitationId?: string
}

export type NotificationSeriesType = {
    id: string
    count: number
}