import {ManualReconciliationLogType} from "./manual-reconciliation-log.type"
import {exportAsCsv} from "../../../tech/csv/download"

export function exportManualReconciliationLogs(manualReconciliationLogs: ManualReconciliationLogType[]) {
    const pathsToIgnore = [
        "investment.deal",
        "investment.documents",
        "investment.investor.dealGroups",
        "investment.investor.investorGroups",
        "investment.investor.kyc",
        "investment.investor.user"
    ]
    const pathsToIncludeDespiteIgnore = [
        "investment.deal.id",
        "investment.deal.name",
    ]
    exportAsCsv(
        "manual-reconciliation-logs.csv",
        manualReconciliationLogs,
        pathsToIgnore,
        pathsToIncludeDespiteIgnore
    )
}