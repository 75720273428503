import React, {useEffect, useState} from "react"
import {useAuth} from "@aws-amplify/ui-react/internal"
import {withAuthenticator} from "@aws-amplify/ui-react"
import {Route, Routes} from "react-router-dom"
import Layout from "./tech/layout/layout.component"
import LoadingDots from "./tech/loading/dots/dots.component"
import {
    ASYNCHRONOUS_ACTION_OVERVIEW,
    COLLECTION_DEVIATIONS,
    COMPANIES,
    COMPANY_CONTACT_NEW,
    COMPANY_CONTACTS,
    COMPANY_NEW,
    DASHBOARD,
    DEAL_CATEGORIES,
    DEAL_CATEGORY_NEW,
    DEAL_GEOGRAPHIES,
    DEAL_GEOGRAPHY_NEW,
    DEAL_GROUP_NEW,
    DEAL_GROUPS,
    DEAL_NEW,
    DEALS,
    FAQ_ENTRIES,
    FAQ_ENTRY_NEW,
    FILE_NEW,
    FILES,
    INVESTMENTS,
    INVESTOR_GROUP_NEW,
    INVESTOR_GROUPS,
    INVESTOR_NEW,
    INVESTORS,
    KYC_REVIEWS,
    MANUAL_RECONCILIATION_BANK_ACCOUNT_NEW,
    MANUAL_RECONCILIATION_BANK_ACCOUNTS,
    MANUAL_RECONCILIATION_LOG_NEW,
    MANUAL_RECONCILIATION_LOGS,
    SINGLE_PURPOSE_VEHICLE_NEW,
    SINGLE_PURPOSE_VEHICLES,
    SYNDICATE_NEW,
    SYNDICATES,
    UPDATE_NEW,
    UPDATES,
    USERS,
    VALUATIONS
} from "./paths"
import FetchContext from "./tech/fetch/fetch.context"
import {FetchClient} from "./tech/fetch/client"
import Dashboard from "./domain/dashboard/dashboard.component"
import DealDelete from "./domain/deal/delete/delete.component"
import DealEdit from "./domain/deal/edit/edit.component"
import DealNew from "./domain/deal/new/new.component"
import DealOverview from "./domain/deal/overview.component"
import DealGroupNew from "./domain/deal-group/new.component"
import DealGroupOverview from "./domain/deal-group/overview.component"
import InvestorOverview from "./domain/investor/overview/overview.component"
import InvestorGroupOverview from "./domain/investor-group/overview.component"
import UserEdit from "./domain/user/edit.component"
import UserOverview from "./domain/user/overview.component"
import "@aws-amplify/ui-react/styles.css"
import "./tech/amplify/amplify"
import DealGroupDelete from "./domain/deal-group/delete.component"
import DealGroupEdit from "./domain/deal-group/edit.component"
import InvestorNew from "./domain/investor/new/new.component"
import InvestorEdit from "./domain/investor/edit/edit.component"
import InvestorDelete from "./domain/investor/delete/delete.component"
import OpenKycReviewsOverview from "./domain/kyc/log/review/overview.component"
import OpenKycReview from "./domain/kyc/log/review/review.component"
import InvestmentOverview from "./domain/investment/overview.component"
import InvestmentSingle from "./domain/investment/single.component"
import InvestorKycLogs from "./domain/investor/kyc/logs/kyc-logs.component"
import CollectionDeviationOverview from "./domain/collection/deviation/overview.component"
import CollectionDeviationSingle from "./domain/collection/deviation/single.component"
import FileOverview from "./domain/file/overview/overview.component"
import FileNew from "./domain/file/new/new.component"
import DealCategoryDelete from "./domain/deal-category/delete.component"
import DealCategoryNew from "./domain/deal-category/new.component"
import DealCategoryEdit from "./domain/deal-category/edit.component"
import DealCategoryOverview from "./domain/deal-category/overview.component"
import DealGeographyOverview from "./domain/deal-geography/overview.component"
import DealGeographyEdit from "./domain/deal-geography/edit.component"
import DealGeographyNew from "./domain/deal-geography/new.component"
import DealGeographyDelete from "./domain/deal-geography/delete.component"
import InvestorGroupDelete from "./domain/investor-group/delete.component"
import InvestorGroupEdit from "./domain/investor-group/edit.component"
import InvestorGroupNew from "./domain/investor-group/new.component"
import SinglePurposeVehicleDelete from "./domain/single-purpose-vehicle/delete.component"
import SinglePurposeVehicleNew from "./domain/single-purpose-vehicle/new.component"
import SinglePurposeVehicleEdit from "./domain/single-purpose-vehicle/edit.component"
import SinglePurposeVehicleOverview from "./domain/single-purpose-vehicle/overview.component"
import ValuationOverview from "./domain/valuation/overview/overview.component"
import FileEdit from "./domain/file/edit/edit.component"
import FileDelete from "./domain/file/delete/delete.component"
import SubscriptionLogs from "./domain/investor/subscription-logs/subscription-logs.component"
import AsynchronousActionErrorOverview from "./tech/asynchronous/action/overview/overview.component"
import DealPublish from "./domain/deal/edit/publish/publish.component"
import DealUnpublish from "./domain/deal/edit/unpublish/unpublish.component"
import DealOutcome from "./domain/deal/edit/outcome/outcome.component"
import CompanyContactOverview from "./domain/company/contact/overview.component"
import CompanyOverview from "./domain/company/overview.component"
import CompanyDelete from "./domain/company/delete.component"
import CompanyContactDelete from "./domain/company/contact/delete.component"
import CompanyNew from "./domain/company/new.component"
import CompanyContactNew from "./domain/company/contact/new.component"
import CompanyEdit from "./domain/company/edit.component"
import CompanyContactEdit from "./domain/company/contact/edit.component"
import FaqEntryOverview from "./domain/faq-entry/overview.component"
import FaqEntryDelete from "./domain/faq-entry/delete.component"
import FaqEntryEdit from "./domain/faq-entry/edit.component"
import FaqEntryNew from "./domain/faq-entry/new.component"
import ManualReconciliationBankAccountDelete from "./domain/reconciliation/account/delete.component"
import ManualReconciliationBankAccountEdit from "./domain/reconciliation/account/edit.component"
import ManualReconciliationBankAccountOverview from "./domain/reconciliation/account/overview.component"
import ManualReconciliationBankAccountNew from "./domain/reconciliation/account/new.component"
import ManualReconciliationLogOverview from "./domain/reconciliation/log/overview.component"
import ManualReconciliationLogEdit from "./domain/reconciliation/log/edit.component"
import ManualReconciliationLogNew from "./domain/reconciliation/log/new.component"
import SyndicateDelete from "./domain/syndicate/delete.component"
import SyndicateEdit from "./domain/syndicate/edit.component"
import SyndicateNew from "./domain/syndicate/new.component"
import SyndicateOverview from "./domain/syndicate/overview.component"
import SyndicateMembersAndInvitationsOverview from "./domain/syndicate/member-invitations.component"
import SyndicateInvitationNew from "./domain/syndicate/invitation/new.component"
import SyndicateInvitationResend from "./domain/syndicate/invitation/resend.component"
import SyndicateInvitationDelete from "./domain/syndicate/invitation/delete.component"
import SyndicateMemberRemove from "./domain/syndicate/member/remove.component"
import {fetchAuthSession} from "aws-amplify/auth"
import {AuthSession} from "@aws-amplify/core/dist/esm/singleton/Auth/types"
import DealSendPaymentInstructions from "./domain/deal/edit/payment-instructions/send-payment-instructions.component"
import UpdateOverview from "./domain/update/overview.component"
import UpdateNew from "./domain/update/new.component"
import UpdateEdit from "./domain/update/edit.component"
import UpdateDelete from "./domain/update/delete.component"

const App = () => {
    const auth = useAuth()
    const [initialAuthSession, setInitialAuthSession] = useState<AuthSession>()

    useEffect(() => {
        fetchAuthSession()
            .then(authSession => setInitialAuthSession(authSession))
            .catch(err => console.error("Failed to fetch auth session", err))
    }, [auth.isLoading])

    if (!initialAuthSession) {
        return <LoadingDots/>
    }

    return (
        <FetchContext.Provider value={new FetchClient(initialAuthSession.tokens)}>
            <Layout>
                <Routes>
                    <Route path={DASHBOARD} element={<Dashboard/>} />
                    <Route path={ASYNCHRONOUS_ACTION_OVERVIEW} element={<AsynchronousActionErrorOverview/>} />
                    <Route path={COLLECTION_DEVIATIONS} element={<CollectionDeviationOverview/>} />
                    <Route path={COLLECTION_DEVIATIONS + `:id`} element={<CollectionDeviationSingle/>} />
                    <Route path={COMPANIES} element={<CompanyOverview/>} />
                    <Route path={COMPANIES + `:id`} element={<CompanyEdit/>} />
                    <Route path={COMPANY_NEW} element={<CompanyNew/>} />
                    <Route path={COMPANIES + `:id/delete`} element={<CompanyDelete/>} />
                    <Route path={COMPANY_CONTACTS} element={<CompanyContactOverview/>} />
                    <Route path={COMPANY_CONTACTS + `:id`} element={<CompanyContactEdit/>} />
                    <Route path={COMPANY_CONTACT_NEW} element={<CompanyContactNew/>} />
                    <Route path={COMPANY_CONTACTS + `:id/delete`} element={<CompanyContactDelete/>} />
                    <Route path={DEALS} element={<DealOverview/>} />
                    <Route path={DEALS + `:id`} element={<DealEdit/>} />
                    <Route path={DEAL_NEW} element={<DealNew/>} />
                    <Route path={DEALS + `:id/delete`} element={<DealDelete/>} />
                    <Route path={DEALS + `:id/outcome`} element={<DealOutcome/>} />
                    <Route path={DEALS + `:id/publish`} element={<DealPublish/>} />
                    <Route path={DEALS + `:id/unpublish`} element={<DealUnpublish/>} />
                    <Route path={DEALS + `:id/send-payment-instructions`} element={<DealSendPaymentInstructions/>} />
                    <Route path={DEAL_CATEGORIES} element={<DealCategoryOverview/>} />
                    <Route path={DEAL_CATEGORIES + `:id`} element={<DealCategoryEdit/>} />
                    <Route path={DEAL_CATEGORY_NEW} element={<DealCategoryNew/>} />
                    <Route path={DEAL_CATEGORIES + `:id/delete`} element={<DealCategoryDelete/>} />
                    <Route path={DEAL_GEOGRAPHIES} element={<DealGeographyOverview/>} />
                    <Route path={DEAL_GEOGRAPHIES + `:id`} element={<DealGeographyEdit/>} />
                    <Route path={DEAL_GEOGRAPHY_NEW} element={<DealGeographyNew/>} />
                    <Route path={DEAL_GEOGRAPHIES + `:id/delete`} element={<DealGeographyDelete/>} />
                    <Route path={DEAL_GROUPS} element={<DealGroupOverview/>} />
                    <Route path={DEAL_GROUPS + `:id`} element={<DealGroupEdit/>} />
                    <Route path={DEAL_GROUP_NEW} element={<DealGroupNew/>} />
                    <Route path={DEAL_GROUPS + `:id/delete`} element={<DealGroupDelete/>} />
                    <Route path={FAQ_ENTRIES} element={<FaqEntryOverview/>} />
                    <Route path={FAQ_ENTRIES + `:id`} element={<FaqEntryEdit/>} />
                    <Route path={FAQ_ENTRY_NEW} element={<FaqEntryNew/>} />
                    <Route path={FAQ_ENTRIES + `:id/delete`} element={<FaqEntryDelete/>} />
                    <Route path={FILES} element={<FileOverview/>} />
                    <Route path={FILES + `:id`} element={<FileEdit/>} />
                    <Route path={FILE_NEW} element={<FileNew/>} />
                    <Route path={FILES + `:id/delete`} element={<FileDelete/>} />
                    <Route path={INVESTMENTS} element={<InvestmentOverview/>} />
                    <Route path={INVESTMENTS + `:id`} element={<InvestmentSingle/>} />
                    <Route path={INVESTORS} element={<InvestorOverview/>} />
                    <Route path={INVESTORS + `:id`} element={<InvestorEdit/>} />
                    <Route path={INVESTOR_NEW} element={<InvestorNew/>} />
                    <Route path={INVESTORS + `:id/delete`} element={<InvestorDelete/>} />
                    <Route path={INVESTORS + `:id/kyc-logs`} element={<InvestorKycLogs/>} />
                    <Route path={INVESTORS + `:id/subscription-logs`} element={<SubscriptionLogs/>} />
                    <Route path={INVESTOR_GROUPS} element={<InvestorGroupOverview/>} />
                    <Route path={INVESTOR_GROUPS + `:id`} element={<InvestorGroupEdit/>} />
                    <Route path={INVESTOR_GROUP_NEW} element={<InvestorGroupNew/>} />
                    <Route path={INVESTOR_GROUPS + `:id/delete`} element={<InvestorGroupDelete/>} />
                    <Route path={KYC_REVIEWS} element={<OpenKycReviewsOverview/>} />
                    <Route path={KYC_REVIEWS + `:id`} element={<OpenKycReview/>} />
                    <Route path={MANUAL_RECONCILIATION_BANK_ACCOUNTS} element={<ManualReconciliationBankAccountOverview/>} />
                    <Route path={MANUAL_RECONCILIATION_BANK_ACCOUNTS + `:id`} element={<ManualReconciliationBankAccountEdit/>} />
                    <Route path={MANUAL_RECONCILIATION_BANK_ACCOUNT_NEW} element={<ManualReconciliationBankAccountNew/>} />
                    <Route path={MANUAL_RECONCILIATION_BANK_ACCOUNTS + `:id/delete`} element={<ManualReconciliationBankAccountDelete/>} />
                    <Route path={MANUAL_RECONCILIATION_LOGS} element={<ManualReconciliationLogOverview/>} />
                    <Route path={MANUAL_RECONCILIATION_LOGS + `:id`} element={<ManualReconciliationLogEdit/>} />
                    <Route path={MANUAL_RECONCILIATION_LOG_NEW} element={<ManualReconciliationLogNew/>} />
                    <Route path={SINGLE_PURPOSE_VEHICLES} element={<SinglePurposeVehicleOverview/>} />
                    <Route path={SINGLE_PURPOSE_VEHICLES + `:id`} element={<SinglePurposeVehicleEdit/>} />
                    <Route path={SINGLE_PURPOSE_VEHICLE_NEW} element={<SinglePurposeVehicleNew/>} />
                    <Route path={SINGLE_PURPOSE_VEHICLES + `:id/delete`} element={<SinglePurposeVehicleDelete/>} />
                    <Route path={SYNDICATES} element={<SyndicateOverview/>} />
                    <Route path={SYNDICATES + `:id`} element={<SyndicateEdit/>} />
                    <Route path={SYNDICATE_NEW} element={<SyndicateNew/>} />
                    <Route path={SYNDICATES + `:id/delete`} element={<SyndicateDelete/>} />
                    <Route path={SYNDICATES + `:syndicateId/members-invitations`} element={<SyndicateMembersAndInvitationsOverview/>} />
                    <Route path={SYNDICATES + `:syndicateId/invitation/new`} element={<SyndicateInvitationNew/>} />
                    <Route path={SYNDICATES + `:syndicateId/invitation/:invitationId/delete`} element={<SyndicateInvitationDelete/>} />
                    <Route path={SYNDICATES + `:syndicateId/invitation/:invitationId/resend`} element={<SyndicateInvitationResend/>} />
                    <Route path={SYNDICATES + `:syndicateId/member/:investorId/remove`} element={<SyndicateMemberRemove/>} />
                    <Route path={UPDATES} element={<UpdateOverview/>} />
                    <Route path={UPDATES + `:id`} element={<UpdateEdit/>} />
                    <Route path={UPDATES + `:id/delete`} element={<UpdateDelete/>} />
                    <Route path={UPDATE_NEW} element={<UpdateNew/>} />
                    <Route path={USERS} element={<UserOverview/>} />
                    <Route path={USERS + `:id`} element={<UserEdit/>} />
                    <Route path={VALUATIONS} element={<ValuationOverview/>} />
                </Routes>
            </Layout>
        </FetchContext.Provider>
    )
}

export default withAuthenticator(App)