/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationReferenceOpenApi
 */
export interface NotificationReferenceOpenApi {
    /**
     * 
     * @type {string}
     * @memberof NotificationReferenceOpenApi
     */
    investmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationReferenceOpenApi
     */
    investorId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationReferenceOpenApi
     */
    subscriptionLogId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationReferenceOpenApi
     */
    syndicateInvitationId?: string;
}

/**
 * Check if a given object implements the NotificationReferenceOpenApi interface.
 */
export function instanceOfNotificationReferenceOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationReferenceOpenApiFromJSON(json: any): NotificationReferenceOpenApi {
    return NotificationReferenceOpenApiFromJSONTyped(json, false);
}

export function NotificationReferenceOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationReferenceOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investmentId': !exists(json, 'investmentId') ? undefined : json['investmentId'],
        'investorId': !exists(json, 'investorId') ? undefined : json['investorId'],
        'subscriptionLogId': !exists(json, 'subscriptionLogId') ? undefined : json['subscriptionLogId'],
        'syndicateInvitationId': !exists(json, 'syndicateInvitationId') ? undefined : json['syndicateInvitationId'],
    };
}

export function NotificationReferenceOpenApiToJSON(value?: NotificationReferenceOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investmentId': value.investmentId,
        'investorId': value.investorId,
        'subscriptionLogId': value.subscriptionLogId,
        'syndicateInvitationId': value.syndicateInvitationId,
    };
}

