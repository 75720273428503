import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {
    CurrencyOpenApi,
    DealFiltersOpenApi,
    DealIndustryOpenApi,
    DealOutcomeOpenApi,
    DealStageOpenApi
} from "../../generated"
import {mapDealIndustryToString, mapDealOutcomeToString, mapDealStageToString} from "./deal.type"
import {mapCurrencyToString} from "../currency/currency.mapper"

export type DealFilterType = "ID"
    | "CATEGORY_NAME"
    | "CURRENCY"
    | "DEAL_GROUP_NAME"
    | "EXCLUSIVE"
    | "EXITED"
    | "GEOGRAPHY_NAME"
    | "INDUSTRY"
    | "INVESTOR_GROUP_NAME"
    | "NAME"
    | "OUTCOME"
    | "PUBLISHED"
    | "STAGE"

export function assembleFilterOptions(): ActionsFilterType<DealFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "CATEGORY_NAME",
            label: "Category Name",
            value: "STRING"
        },
        {
            type: "CURRENCY",
            label: "Currency",
            value: {
                type: "ENUM",
                values: Object.entries(CurrencyOpenApi).map(c => ({
                    label: mapCurrencyToString(c[1]),
                    value: c[1]
                }))
            }
        },
        {
            type: "DEAL_GROUP_NAME",
            label: "Deal Group Name",
            value: "STRING"
        },
        {
            type: "EXCLUSIVE",
            label: "Exclusive",
            value: "BOOLEAN"
        },
        {
            type: "EXITED",
            label: "Exited",
            value: "BOOLEAN"
        },
        {
            type: "GEOGRAPHY_NAME",
            label: "Geography Name",
            value: "STRING"
        },
        {
            type: "INDUSTRY",
            label: "Industry",
            value: {
                type: "ENUM",
                values: Object.entries(DealIndustryOpenApi).map(i => ({
                    label: mapDealIndustryToString(i[1]),
                    value: i[1]
                }))
            }
        },
        {
            type: "INVESTOR_GROUP_NAME",
            label: "Investor Group Name",
            value: "STRING"
        },
        {
            type: "NAME",
            label: "Name",
            value: "STRING"
        },
        {
            type: "OUTCOME",
            label: "Outcome",
            value: {
                type: "ENUM",
                values: Object.entries(DealOutcomeOpenApi).map(s => ({
                    label: mapDealOutcomeToString(s[1]),
                    value: s[1]
                }))
            }
        },
        {
            type: "PUBLISHED",
            label: "Published",
            value: "BOOLEAN"
        },
        {
            type: "STAGE",
            label: "Stage",
            value: {
                type: "ENUM",
                values: Object.entries(DealStageOpenApi).map(s => ({
                    label: mapDealStageToString(s[1]),
                    value: s[1]
                }))
            }
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<DealFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<DealFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return {
        type: "NAME",
        value: undefined
    }
}

export function mapToDealFiltersOpenApi(filter: ActionsFilterTypeAndValueType<DealFilterType> | undefined): DealFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "CATEGORY_NAME":
            return { categoryName: filter.value }
        case "CURRENCY":
            return { currency: filter.value }
        case "DEAL_GROUP_NAME":
            return { dealGroupName: filter.value }
        case "EXCLUSIVE":
            return { exclusive: filter.value }
        case "EXITED":
            return { exited: filter.value }
        case "GEOGRAPHY_NAME":
            return { geographyName: filter.value }
        case "INDUSTRY":
            return { industry: filter.value }
        case "INVESTOR_GROUP_NAME":
            return { investorGroupName: filter.value }
        case "NAME":
            return { name: filter.value }
        case "OUTCOME":
            return { outcome: filter.value }
        case "PUBLISHED":
            return { published: filter.value }
        case "STAGE":
            return { stage: filter.value }
    }
}