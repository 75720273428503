import {AuthTokens, fetchAuthSession} from "aws-amplify/auth"
import {
    ActionApiClient,
    CollectionApiClient,
    CompanyApiClient,
    Configuration,
    DealApiClient,
    DealCategoryApiClient,
    DealGeographyApiClient,
    DealGroupApiClient,
    DocumentApiClient,
    FAQEntryApiClient,
    FileApiClient,
    InvestmentApiClient,
    InvestorApiClient,
    InvestorGroupApiClient,
    KycApiClient,
    LoggingApiClient,
    ReconciliationApiClient,
    ShareClassApiClient,
    SinglePurposeVehicleApiClient,
    SyndicateApiClient,
    UpdateApiClient,
    UserApiClient,
    ValuationApiClient
} from "../../generated"
import {DealApi} from "../../domain/deal/deal.api"
import {DealGroupApi} from "../../domain/deal-group/deal-group.api"
import {InvestorApi} from "../../domain/investor/investor.api"
import {UserApi} from "../../domain/user/user.api"
import {KycApi} from "../../domain/kyc/kyc.api"
import {FileApi} from "../../domain/file/file.api"
import {DocumentApi} from "../../domain/document/document.api"
import {InvestmentApi} from "../../domain/investment/investment.api"
import {CollectionApi} from "../../domain/collection/collection.api"
import {DealCategoryApi} from "../../domain/deal-category/deal-category.api"
import {DealGeographyApi} from "../../domain/deal-geography/deal-geography.api"
import {InvestorGroupApi} from "../../domain/investor-group/investor-group.api"
import {SinglePurposeVehicleApi} from "../../domain/single-purpose-vehicle/single-purpose-vehicle.api"
import {ValuationApi} from "../../domain/valuation/valuation.api"
import {RequestErrorMiddleware} from "../logging/error-middleware"
import {LoggingApi} from "../logging/logging.api"
import {getEnvironment} from "../environment/environment.util"
import {Environment} from "../environment/environment.enum"
import {AsynchronousActionApi} from "../asynchronous/action/action.api"
import {CompanyApi} from "../../domain/company/company.api"
import {CompanyContactApi} from "../../domain/company/contact/contact.api"
import {FaqEntryApi} from "../../domain/faq-entry/faq-entry.api"
import {ReconciliationApi} from "../../domain/reconciliation/reconciliation.api"
import {SyndicateApi} from "../../domain/syndicate/syndicate.api"
import {ShareClassApi} from "../../domain/share-class/share-class.api"
import {UpdateApi} from "../../domain/update/update.api"

export class FetchClient {

    // @ts-ignore
    private apis: APIs

    constructor(initialTokens: AuthTokens | undefined) {
        this.initializeApis(this.assembleConfiguration(initialTokens))
        setInterval(async () => {
            await this.updateAccessToken()
        }, 120_000) // refresh token every 2 minutes
    }

    get actionApi(): AsynchronousActionApi {
        return new AsynchronousActionApi(this.apis.actionApiClient)
    }

    get collectionApi(): CollectionApi {
        return new CollectionApi(this.apis.collectionApiClient)
    }

    get companyApi(): CompanyApi {
        return new CompanyApi(this.apis.companyApiClient)
    }

    get companyContactApi(): CompanyContactApi {
        return new CompanyContactApi(this.apis.companyContactApiClient)
    }

    get dealApi(): DealApi {
        return new DealApi(this.apis.dealApiClient)
    }

    get dealCategoryApi(): DealCategoryApi {
        return new DealCategoryApi(this.apis.dealCategoryApiClient)
    }

    get dealGeographyApi(): DealGeographyApi {
        return new DealGeographyApi(this.apis.dealGeographyApiClient)
    }

    get dealGroupApi(): DealGroupApi {
        return new DealGroupApi(this.apis.dealGroupApiClient)
    }

    get documentApi(): DocumentApi {
        return new DocumentApi(this.apis.documentApiClient)
    }

    get faqEntryApi(): FaqEntryApi {
        return new FaqEntryApi(this.apis.faqEntryApiClient)
    }

    get fileApi(): FileApi {
        return new FileApi(this.apis.fileApiClient)
    }

    get investmentApi(): InvestmentApi {
        return new InvestmentApi(this.apis.investmentApiClient)
    }

    get investorApi(): InvestorApi {
        return new InvestorApi(this.apis.investorApiClient)
    }

    get investorGroupApi(): InvestorGroupApi {
        return new InvestorGroupApi(this.apis.investorGroupApiClient)
    }

    get kycApi(): KycApi {
        return new KycApi(this.apis.kycApiClient)
    }

    get loggingApi(): LoggingApi {
        return new LoggingApi(this.apis.loggingApiClient)
    }

    get reconciliationApi(): ReconciliationApi {
        return new ReconciliationApi(this.apis.reconciliationApiClient)
    }

    get shareClassApi(): ShareClassApi {
        return new ShareClassApi(this.apis.shareClassApiClient)
    }

    get singlePurposeVehicleApi(): SinglePurposeVehicleApi {
        return new SinglePurposeVehicleApi(this.apis.singlePurposeVehicleApiClient)
    }

    get syndicateApi(): SyndicateApi {
        return new SyndicateApi(this.apis.syndicateApiClient)
    }

    get updateApi(): UpdateApi {
        return new UpdateApi(this.apis.updateApiClient)
    }

    get userApi(): UserApi {
        return new UserApi(this.apis.userApiClient)
    }

    get valuationApi(): ValuationApi {
        return new ValuationApi(this.apis.valuationApiClient)
    }

    private async updateAccessToken() {
        try {
            const authSession = await fetchAuthSession()
            this.initializeApis(this.assembleConfiguration(authSession.tokens))
        }
        catch (err) {
            console.error("Failed to fetch auth session", err)
        }
    }

    private assembleConfiguration(accessToken: AuthTokens | undefined) {
        return new Configuration({
            basePath: this.getBasePath(),
            headers: this.assembleHeaders(accessToken),
            middleware: [ new RequestErrorMiddleware(this) ]
        })
    }

    private getBasePath() {
        switch (getEnvironment()) {
            case Environment.DEVELOP:
            case Environment.PREVIEW:
                return "https://backend-integration.nonpublic.io"
            case Environment.PRODUCTION:
                return "https://backend.nonpublic.io"
            case Environment.LOCAL:
                return "http://localhost:8080"
        }
    }

    private assembleHeaders(accessToken: AuthTokens | undefined) {
        return accessToken
            ? { "Authorization": `Bearer ${accessToken.accessToken.toString()}` }
            : undefined
    }

    private initializeApis(configuration: Configuration) {
        this.apis = {
            actionApiClient: new ActionApiClient(configuration),
            collectionApiClient: new CollectionApiClient(configuration),
            companyApiClient: new CompanyApiClient(configuration),
            companyContactApiClient: new CompanyApiClient(configuration),
            dealApiClient: new DealApiClient(configuration),
            dealCategoryApiClient: new DealCategoryApiClient(configuration),
            dealGeographyApiClient: new DealGeographyApiClient(configuration),
            dealGroupApiClient: new DealGroupApiClient(configuration),
            documentApiClient: new DocumentApiClient(configuration),
            faqEntryApiClient: new FAQEntryApiClient(configuration),
            fileApiClient: new FileApiClient(configuration),
            investmentApiClient: new InvestmentApiClient(configuration),
            investorApiClient: new InvestorApiClient(configuration),
            investorGroupApiClient: new InvestorGroupApiClient(configuration),
            kycApiClient: new KycApiClient(configuration),
            loggingApiClient: new LoggingApiClient(configuration),
            reconciliationApiClient: new ReconciliationApiClient(configuration),
            shareClassApiClient: new ShareClassApiClient(configuration),
            singlePurposeVehicleApiClient: new SinglePurposeVehicleApiClient(configuration),
            syndicateApiClient: new SyndicateApiClient(configuration),
            updateApiClient: new UpdateApiClient(configuration),
            userApiClient: new UserApiClient(configuration),
            valuationApiClient: new ValuationApiClient(configuration),
        }
    }

}

type APIs = {
    actionApiClient: ActionApiClient
    collectionApiClient: CollectionApiClient
    companyApiClient: CompanyApiClient
    companyContactApiClient: CompanyApiClient
    dealApiClient: DealApiClient
    dealCategoryApiClient: DealCategoryApiClient
    dealGeographyApiClient: DealGeographyApiClient
    dealGroupApiClient: DealGroupApiClient
    documentApiClient: DocumentApiClient
    faqEntryApiClient: FAQEntryApiClient
    fileApiClient: FileApiClient
    investmentApiClient: InvestmentApiClient
    investorApiClient: InvestorApiClient
    investorGroupApiClient: InvestorGroupApiClient
    kycApiClient: KycApiClient
    loggingApiClient: LoggingApiClient
    reconciliationApiClient: ReconciliationApiClient
    shareClassApiClient: ShareClassApiClient
    singlePurposeVehicleApiClient: SinglePurposeVehicleApiClient
    syndicateApiClient: SyndicateApiClient
    updateApiClient: UpdateApiClient
    userApiClient: UserApiClient
    valuationApiClient: ValuationApiClient
}