import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {DealType} from "../deal/deal.type"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import styles from "./dashboard.module.css"
import {Link} from "react-router-dom"
import {DEAL} from "../../paths"

const DashboardDealsWithoutUpdate: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [dealsWithoutUpdate, setDealsWithoutUpdate] = useState<DealType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealsWithoutUpdate(await fetchClient.dealApi.getAllWithoutUpdate())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <div className={styles.box}>
            <div>
                <h3>Deals without update</h3>
                {state === "LOADED" && (
                    <>
                        {dealsWithoutUpdate.length > 0 ? (
                            <>
                                The following deals have no update:
                                <ul>
                                    {dealsWithoutUpdate.map(d => (
                                        <li key={`deal-without-update-${d.id!}`}>
                                            <Link to={DEAL(d.id!)}>
                                                {d.name.completeOnboarding}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <>No deals without update.</>
                        )}
                    </>
                )}
                {state === "LOADING" && <LoadingDots/>}
                {state === "ERROR" && <i>Error while loading.</i>}
            </div>
        </div>
    )
}

export default DashboardDealsWithoutUpdate