import React, {useContext, useEffect, useMemo, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import {MANUAL_RECONCILIATION_LOG_NEW} from "../../../paths"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {PaginatedType} from "../../../tech/actions/pagination/pagination.type"
import {emptyPaginated, getPagination} from "../../../tech/actions/pagination/pagination.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToManualReconciliationLogFiltersOpenApi} from "./filter.util"
import {ManualReconciliationLogType} from "./manual-reconciliation-log.type"
import Actions from "../../../tech/actions/actions.component"
import ManualReconciliationLogTable from "./table.component"
import {exportManualReconciliationLogs} from "./export.util"
import styles from "./overview.module.sass"

const ManualReconciliationLogOverview = () => {
    const LOGS_PER_PAGE = 50
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [
        manualReconciliationLogs,
        setManualReconciliationLogs
    ] = useState<PaginatedType<ManualReconciliationLogType>>(emptyPaginated)

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(LOGS_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setState("LOADING")
                setManualReconciliationLogs(await fetchClient.reconciliationApi.getAllManualReconciliationLogsPaginated(
                    currentSortOption.id,
                    mapToManualReconciliationLogFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Manual Reconciliation Logs"
                subTitle={`${manualReconciliationLogs.elements.length} logs`}
                button={(
                    <div className={styles.buttons}>
                        <Button
                            title="Export as CSV"
                            style={ButtonStyle.SECONDARY}
                            onClick={() => exportManualReconciliationLogs(manualReconciliationLogs.elements)}
                            type="button"
                        />
                        <Button
                            title="New Log"
                            style={ButtonStyle.SECONDARY}
                            onClick={() => navigate(MANUAL_RECONCILIATION_LOG_NEW)}
                            type="button"
                        />
                    </div>
                )}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "Logs",
                    elementsPerPage: LOGS_PER_PAGE,
                    numberElements: manualReconciliationLogs.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <ManualReconciliationLogTable
                        logs={manualReconciliationLogs.elements}
                        showInvestment
                    />
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load."
                    />
                )}
            </Actions>
        </Section>
    )
}

export default ManualReconciliationLogOverview