import React, {FunctionComponent, useState} from "react"
import {DealInvestorGroupType} from "./deal-investor-group.type"
import {InvestorGroupType} from "../../investor-group/investor-group.type"
import Select from "react-select"
import styles from "../select.module.sass"

type DealInvestorGroupSelectAddProps = {
    add: (group: DealInvestorGroupType) => void
    groups: DealInvestorGroupType[]
    groupsRaw: InvestorGroupType[]
}

const DealInvestorGroupSelectAdd: FunctionComponent<DealInvestorGroupSelectAddProps> = ({ add, groups, groupsRaw }) => {
    const [selectedGroup, setSelectedGroup] = useState<DealInvestorGroupType>()

    const notAlreadySelectedGroups = groupsRaw.filter(ig => !groups.map(g => g.id).includes(ig.id))
    const options = notAlreadySelectedGroups.map(investorGroup => ({
        label: investorGroup.name,
        value: investorGroup
    }))

    return (
        <div className={styles.add}>
            {options.length > 0 ? (
                <>
                    <div className={styles.select}>
                        <Select
                            options={options}
                            value={options.filter((o) => selectedGroup?.id === o.value.id)}
                            onChange={val => {
                                if (val && val.value) {
                                    setSelectedGroup(val.value)
                                }
                            }}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={() => add(selectedGroup!)}
                        disabled={selectedGroup === undefined}
                    >
                        Add
                    </button>
                </>
            ) : (
                <>No more investor groups available.</>
            )}
        </div>
    )
}

export default DealInvestorGroupSelectAdd