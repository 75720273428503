import {compareStrings} from "../sort/sort.util"

type KeyValue = {
    key: string,
    value: string
}

export function writeToCsv(
    objects: object[],
    pathsToIgnore?: string[],
    pathsToIncludeDespiteIgnore?: string[]
): string[][] {
    if (objects.length < 1) throw new Error("No investments available.")
    const heads = assembleHeads(
        objects[0],
        pathsToIgnore || [],
        pathsToIncludeDespiteIgnore || []
    ).sort((a, b) => compareStrings(a, b, "ASCENDING"))
    const rows = assembleRows(objects)
    return [
        heads,
        ...mergeHeadsAndRows(heads, rows)
    ]
}

function assembleHeads(
    obj: object,
    pathsToIgnore: string[],
    pathsToIncludeDespiteIgnore: string[],
    parent?: string
): string[] {
    return Object.keys(obj)
        .flatMap(key => {
            const concatenatedKey = concatenateKey(key, parent)

            // @ts-ignore
            const val = obj[key]
            if (typeof val === "object" && !(val instanceof Date) && val !== null) {
                return assembleHeads(val, pathsToIgnore, pathsToIncludeDespiteIgnore, concatenatedKey)
            }

            if (shouldIgnore(concatenatedKey, pathsToIgnore, pathsToIncludeDespiteIgnore)) {
                return [undefined]
            }

            return [concatenatedKey]
        })
        .filter(x => x !== undefined) as string[]
}

function shouldIgnore(
    concatenatedKey: string,
    pathsToIgnore: string[],
    pathsToIncludeDespiteIgnore: string[]
): boolean {
    return pathsToIgnore.find(p => concatenatedKey === p || concatenatedKey.startsWith(p + ".")) !== undefined
        && pathsToIncludeDespiteIgnore.find(p => concatenatedKey === p || concatenatedKey.startsWith(p + ".")) === undefined
}

function assembleRows(objects: object[]): KeyValue[][] {
    return objects
        .map(obj => assembleRow(obj))
}

function assembleRow(obj: object, parent?: string): KeyValue[] {
    return Object.keys(obj)
        .flatMap(key => {
            const concatenatedKey = concatenateKey(key, parent)
            // @ts-ignore
            const val = obj[key]
            if (val === undefined || val === null) {
                return [{
                    key: concatenatedKey,
                    value: ""
                }]
            }
            if (typeof val === "object" && !(val instanceof Date)) {
                return assembleRow(val, concatenatedKey)
            }
            return [{
                key: concatenatedKey,
                value: val
            }]
        })
}

function concatenateKey(key: string, parent?: string) {
    return parent === undefined
        ? key
        : parent + "." + key
}

function mergeHeadsAndRows(heads: string[], rows: KeyValue[][]): string[][] {
    return rows.map(row => heads.map(h => {
            const found = row.find(x => x.key === h)
            return found === undefined ? "" : found.value
        }))
}