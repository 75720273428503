import {NotificationAsynchronousActionType, NotificationReferenceType, NotificationSeriesType} from "./action.type"
import {
    NotificationAsynchronousActionOpenApi,
    type NotificationReferenceOpenApi,
    NotificationSeriesOpenApi
} from "../../../../generated"

export function mapOpenApiToNotificationAsynchronousAction(
    action: NotificationAsynchronousActionOpenApi
): NotificationAsynchronousActionType {
    return new NotificationAsynchronousActionType({
        id: action.id,
        created: action.created,
        modified: action.modified,
        state: action.state,
        message: action.message,
        processed: action.processed,
        reference: mapOpenApiToNotificationReference(action.reference),
        series: action.series && mapOpenApiToNotificationSeries(action.series),
        type: action.type
    })
}

export function mapOpenApiToNotificationReference(
    reference: NotificationReferenceOpenApi
): NotificationReferenceType {
    return {
        investmentId: reference.investmentId,
        investorId: reference.investorId,
        subscriptionLogId: reference.subscriptionLogId,
        syndicateInvitationId: reference.syndicateInvitationId,
    }
}

export function mapOpenApiToNotificationSeries(series: NotificationSeriesOpenApi): NotificationSeriesType {
    return {
        id: series.id,
        count: series.count
    }
}